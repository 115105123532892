<template>
  <div class="NewsView">
    <v-row class="ma-0">
      <v-col fluid v-if="!isSingle">
        <v-list color="transparent">
          <v-list-item
            v-for="item in data"
            @click="handleSingle(item)"
            :key="item.id"
            class="mb-4 pa-0"
            style="background-color: #fff"
          >
            <v-list-item-avatar tile size="120" class="ma-0 pa-0">
              <v-img min-width="120" :src="getImageUrl(item)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content class="ml-4">
              <v-list-item-title class="mb-2" style="white-space: normal"
                ><b>{{ item.title }}</b></v-list-item-title
              >
              <v-list-item-subtitle>{{
                date(item.createdAt)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div>
      <article v-if="isSingle">
        <header class="relative" ref="modal">
          <!-- <v-img min-height="150px" :src="getImageUrl(activeItem)"></v-img> -->
          <div
            class="bgi"
            :style="`background-image: url(${getImageUrl(activeItem)})`"
          >
            <div class="shade">
              <h3 class="pa-4">{{ toUppercase(activeItem.title) }}</h3>
            </div>
          </div>
          <v-btn
            @click="isSingle = false"
            class="close-btn"
            fab
            small
            color="primary"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </header>
        <div class="article-content" style="background-color: #fff">
          <div class="pa-4" v-html="activeItem.html"></div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import moment from "moment";
import { IMAGE_BASE } from "../util/constants.js";

export default {
  name: "News",
  components: {},
  data() {
    return {
      data: null,
      isSingle: false,
      activeItem: null,
    };
  },
  created() {},
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `client/news`,
          data: null,
        })
        .then((res) => {
          console.log(res);
          this.data = res;
          this.activeItem = null;
        });
    },
    handleSingle(item) {
      this.activeItem = item;
      this.isSingle = true;
      setTimeout(() => {
        this.$refs.modal.scrollTop = 0;
      }, 0);
    },
    date(unix) {
      return moment.unix(unix).format("YYYY.MM.DD");
    },
    getImageUrl(item) {
      let name = item.newsImages[0].filename;
      let folder = item.newsImages[0].dirPath.substring(1);
      return `${IMAGE_BASE}/${folder}${name}`;
    },
    toUppercase(val) {
      return val.toUpperCase();
    },
    saveItem() {},
  },
};
</script>
<style scoped>
.v-list-item {
  border-bottom: 2px solid red;
}
.bgi {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 220px;
}
.shade {
  background-color: rgba(0, 0, 0, 0.5);
}
/*.relative {
  position: relative;
}
article {
  position: relative;
  z-index: 1000;
  transform: translate(0, -80px);
  overflow-x: hidden;
}*/
article h3 {
  color: #fff;
  min-height: 220px;
}
.overlay {
  /*  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, 0.25);*/
}
.close-btn {
  position: fixed !important;
  right: 10px;
  top: 8px;
  z-index: 10000;
}
</style>
